import { inject } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  CanActivateFn,
  CanDeactivateFn,
  CanMatchFn,
  GuardResult,
  MaybeAsync,
  RedirectCommand,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  type UrlTree,
} from '@angular/router';
import { AuthService, LOGIN_ROUTE } from './auth.service';

/**
 * AccessGuardCanMatchFn
 * This function is used as a guard for checking if the current user has access to the requested route.
 * It checks if the user has access to the route path by calling the userHasAccessToRoute() function from the AuthService.
 * The result of this function will determine if the user is allowed to navigate to the route or not.
 *
 *
 * @param route
 * @param segments
 * @constructor
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const AccessGuardCanMatchFn: CanMatchFn = (
  route: Route,
  segments: UrlSegment[],
): MaybeAsync<GuardResult> => {
  const authService = inject(AuthService);
  return authService.userHasAccessToRoute(route.path);
};

/**
 * @param route
 * @param state
 * @constructor
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 */
export const AccessGuardCanActivate: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): MaybeAsync<GuardResult> => {
  const authService = inject(AuthService);
  const isFullyAuthenticated = authService.isFullyAuthenticated();

  if (!isFullyAuthenticated) {
    authService.logout({ clearServerSession: true });

    const router: Router = inject(Router);
    const urlTree: UrlTree = router.parseUrl(LOGIN_ROUTE);

    return new RedirectCommand(urlTree, { skipLocationChange: true });
  }

  return isFullyAuthenticated;
};

/**
 *
 * @param childRoute
 * @param state
 * @constructor
 *
 * @author Carlos Duardo <charlieandroid55@gmail.com>
 *
 */
export const AccessGuardCanActivateChildFn: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
): MaybeAsync<GuardResult> => {
  const authService = inject(AuthService);
  return authService.userHasAccessToRoute(childRoute.routeConfig?.path);
};

export interface CanDeactivateComponent {
  //TODO: add parameter to know which route im going to
  canDeactivate: () => MaybeAsync<boolean>;
}

/**
 * This function is used to determine if a component can be deactivated,
 * this component must implements CanDeactivateComponent interface
 *
 * @param component
 * @param currentRoute
 * @param currentState
 * @param nextState
 * @returns
 */
export const AccessGuardCanDeactivateFn: CanDeactivateFn<
  CanDeactivateComponent
> = (
  component: CanDeactivateComponent,
  currentRoute: ActivatedRouteSnapshot,
  currentState: RouterStateSnapshot,
  nextState: RouterStateSnapshot,
): MaybeAsync<GuardResult> => {
  return component.canDeactivate();
};
